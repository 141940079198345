<template>
  <div class="tw-text-left purchase-history">
    <v-row>
      <v-col>
        <h2>Order History</h2>
      </v-col>
    </v-row>
    <v-row v-if="!isDataLoading && transactions && upgradedTrips">
      <v-col>
        <v-tabs
          class="mb-10"
          v-model="tab"
          background-color="transparent"
          color="#000000"
          grow
        >
          <v-tab key="transactions"> TRANSACTIONS </v-tab>
          <v-tab key="trips"> UPGRADED TRIPS </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="transactions">
            <div
              class="transaction-list"
              v-if="transactions && transactions.length > 0"
            >
              <div
                class="transaction-item pt-5"
                v-for="transaction in transactions"
                :key="transaction.id"
              >
                <v-row>
                  <v-col class="pb-0">
                    {{ formatDate(transaction.createdAt) }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="9">
                    <h3>{{ transaction.productName }}</h3>
                    <p>
                      <small>Order Number: {{ transaction.uuid }}</small>
                    </p>
                  </v-col>
                  <v-col cols="3" class="tw-text-right">
                    <h3>${{ (transaction.amount / 100).toFixed(2) }}</h3>
                  </v-col>
                </v-row>
                <v-divider />
              </div>
            </div>
            <div v-if="!transactions || transactions.length === 0">
              <p class="tw-text-center">You don't have any purchases yet!</p>
            </div>
          </v-tab-item>

          <v-tab-item key="trips">
            <div
              class="trips-list"
              v-if="upgradedTrips && upgradedTrips.length > 0"
            >
              <div
                class="trip-item"
                v-for="upgrade in upgradedTrips"
                :key="upgrade.id"
              >
                <v-col>
                  {{ formatDate(upgrade.createdAt) }}
                </v-col>
                <v-col
                  class="mb-5 tw-rounded-lg tw-py-3 tw-px-5 tw-cursor-pointer tw-bg-lighter-grey tw-border tw-border-solid tw-border-lightest-grey tw-shadow-custom-sm"
                  cols="12"
                  @click="goToTrip(upgrade.trip)"
                >
                  <v-row align="center">
                    <v-col cols="3">
                      <v-img :src="iconUrl(upgrade.trip.icon)" />
                    </v-col>
                    <v-col cols="7" class="text-left">
                      <h4
                        class="tw-font-semibold tw-truncate tw-text-dark-green-two"
                      >
                        {{ upgrade.trip.title }}
                      </h4>
                      <p class="tw-m-0 tw-text-xs tw-text-dark-green-two">
                        Date:
                        {{
                          getFormattedDateRange(
                            upgrade.trip.startDate,
                            upgrade.trip.endDate
                          )
                        }}
                      </p>
                    </v-col>
                    <v-col cols="2">
                      <v-icon class="tw-text-grey-one">mdi-arrow-right</v-icon>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </div>
            <div v-if="!upgradedTrips || upgradedTrips.length === 0">
              <p class="tw-text-center">You haven't upgraded any trips yet!</p>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";

export default {
  name: "PurchaseHistory",

  props: ["user"],

  mixins: [FormattedDateRange],

  data() {
    return {
      tab: 0,
      isDataLoading: false
    };
  },

  computed: {
    ...mapGetters({
      transactions: "transaction/list",
      upgradedTrips: "wallet-transaction/list"
    })
  },

  methods: {
    async getData() {
      this.isDataLoading = true;
      await this.$store.dispatch("transaction/find", {
        query: {
          $sort: {
            createdAt: -1
          }
        }
      });
      await this.$store.dispatch("wallet-transaction/find", {
        query: {
          include: "trip",
          $sort: {
            createdAt: -1
          }
        }
      });
      this.isDataLoading = false;
    },

    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
    },

    iconUrl(icon) {
      return require(`@/assets/svgs/my_trips/${icon}`);
    },

    async goToTrip(trip) {
      try {
        const res = await this.$store.dispatch("trip/get", [
          trip.id,
          {
            query: {
              include: "users,trip_invitation"
            }
          }
        ]);
        if (res.active && res.users) {
          let member = res.users.filter(
            (user) => user.id === this.$store.state.auth.user.id
          )[0];
          let rsvp = member.trip_invite.status;
          if (rsvp === "declined") {
            this.$router.push({
              name: "CrewEditRoute",
              params: {
                id: trip.id,
                userId: member.id
              }
            });
          } else {
            this.goTo(trip);
          }
        } else {
          this.goTo(trip);
        }
      } catch {
        this.goTo(trip);
      }
    },
    goTo(trip) {
      this.$store.commit("meta/setCurrentTrip", trip);
      this.$router.push({ name: "TripViewRoute", params: { id: trip.id } });
    }
  },

  async beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Purchases",
      bgColor: null,
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      startDate: null,
      showBackButton: true,
      endDate: null
    });

    await this.getData();
  },
  destroyed() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Profile",
      bgColor: "#6A4AE8",
      fontColor: "#FFFFFF",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
  }
};
</script>

<style lang="scss">
.purchase-history {
  h2 {
    font-family: Figtree;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.02em;
  }

  h3 {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.02em;
  }

  .what-you-get {
    p {
      color: #949494;
    }
  }

  .j-premium-text {
    font-weight: 600;
    color: #1743bb;
  }
}
</style>
